<script>
	import { goto } from '$app/navigation';
	import { page, session } from '$app/stores';
	import { account } from '$utils/appwrite-client';
	import { onMount } from 'svelte';

	let email = $page.url.searchParams.get('email');
	let password = $page.url.searchParams.get('password');
	let redirect = $page.url.searchParams.get('redirect') ?? '/order/new';
	let isLoading = email && password ? true : false;
	let error = '';

	onMount(async () => {
		if (email && password) {
			isLoading = true;

			await loginHandler(email, password);
		}
	});

	async function loginHandler(email, password) {
		if (!email || !password) {
			error = 'Missing information';

			return;
		}

		error = '';
		isLoading = true;

		try {
			const { $id } = await account.createEmailSession(email, password);

			const { jwt } = await account.createJWT();

			const res = await fetch('/auth/requests/login', {
				method: 'POST',
				headers: { 'content-type': 'application/json' },
				body: JSON.stringify({ jwt, sessionId: $id })
			});

			if (!res.ok) throw 'Something went wrong';

			const data = await res.json();

			if (data.error) throw data.error;

			$session = { ...$session, isLoggedIn: true, ...data.user };

			await goto(redirect);
		} catch (e) {
			error = e;

			$session.isLoggedIn = false;

			isLoading = false;
		}
	}

	async function slackSlashCommand() {
		const body = new URLSearchParams({ user_id: 'U02NVP4EA5D' });

		await fetch('/slack/login-notion-import', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			},
			body
		});
	}

	function jobsOnUpdate() {
		const body = new URLSearchParams({
			id: '2bf062a1-aae5-4ea4-a2ff-73f3b8be80e1',
			status: 'Confirmed'
		});

		fetch(`/jobs/on-update`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
			},
			body
		});
	}

	async function triggerTimeReport() {
		const body = JSON.stringify({
			slackId: 'U02NVP4EA5D',
			date: '2022-11-22',
			startTime: '08:00',
			endTime: '17:00',
			breakStartTime: '11:00',
			breakEndTime: '11:00',
			notes: 'Robin Testar'
		});

		fetch(`/report-time/requests/create`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body
		});
	}

	async function triggerOrderUpdate() {
		const body = JSON.stringify({
			$collection: '625ef972b746a226f9ec',
			$id: '635a6413d27fa8d68acf'
		});

		const response = await fetch(`/order/requests/on-update`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body
		});

		console.log('response: ', response);
	}

	async function triggerRebuildArticles() {
		const response = await fetch(`/articles/requests/build-articles`);

		console.log('response: ', response);
	}
</script>

{#if isLoading}
	<div class="fixed h-full w-full bg-black z-10 grid place-items-center">
		<h1 class="text-4xl">Logging in..</h1>
	</div>
{/if}

<div
	class="flex flex-col gap-2 place-items-center place-content-center min-h-screen max-w-md mx-auto"
>
	<h2 class="text-2xl mb-10">Grabbarna Flytt Import</h2>

	<form class="w-full px-10 py-2 grid gap-4">
		<input
			class="input"
			name="email"
			autocomplete="username"
			type="email"
			bind:value={email}
			placeholder="Email"
		/>

		<input
			class="input"
			name="password"
			type="password"
			autocomplete="current-password"
			bind:value={password}
			placeholder="Password"
		/>

		<button type="button" class="button mt-4" on:click={() => loginHandler(email, password)}
			>Login</button
		>

		{#if error}
			<p>{error}</p>
		{/if}
	</form>

	{#if !import.meta.env.PROD}
		<button class="p-2 bg-gray-600 rounded my-4 hover:bg-gray-500" on:click={slackSlashCommand}
			>Run Slash Command</button
		>

		<button class="p-2 bg-gray-600 rounded my-4 hover:bg-gray-500" on:click={jobsOnUpdate}
			>Jobs on update</button
		>

		<button class="p-2 bg-gray-600 rounded my-4 hover:bg-gray-500" on:click={triggerTimeReport}
			>Trigger time report</button
		>

		<button class="p-2 bg-gray-600 rounded my-4 hover:bg-gray-500" on:click={triggerOrderUpdate}
			>Trigger order create</button
		>

		<button class="p-2 bg-gray-600 rounded my-4 hover:bg-gray-500" on:click={triggerRebuildArticles}
			>Trigger rebuild articles</button
		>
	{/if}
</div>
